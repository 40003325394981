import {uuid4} from "@capacitor/core/dist/esm/util";
import {BonusMaterial, BonusMaterialRange} from "@/domain/model/bonusMaterial/bonusMaterial.model";
import {Material} from "@/domain/model/material/material.model";
import _ from "lodash";

export interface CreateBonusMaterial {
    id: string;
    ref: string;
}

export interface UpdateBonusMaterial {
    id: string;
    ref: string;
    ranges: CreateBonusMaterialRanges[];
}

export interface CreateBonusMaterialRanges {
    min: number;
    max: number;
    money: number;
    name: string;
}

export function createBonusMaterialRangesFromEntity(entity: BonusMaterialRange): CreateBonusMaterialRanges{
    return {
        min: entity.min,
        max: entity.max,
        money: entity.money,
        name: entity.name,
    }
}
export function defaultCreateBonusMaterialRanges(): CreateBonusMaterialRanges{
    return {
        min: 1,
        max: 999999,
        money: 10,
        name: 'Rango por defecto',
    }
}
export function emptyCreateBonusMaterialRanges(): CreateBonusMaterialRanges{
    return {
        min: 0,
        max: 0,
        money: 0,
        name: 'vacío'
    }
}

export function emptyUpdateBonusMaterial(): UpdateBonusMaterial {
    return {
        id: uuid4(),
        ref: '',
        ranges: [defaultCreateBonusMaterialRanges()],
    }
}

export function updateBonusMaterialFromEntity(entity: BonusMaterial): UpdateBonusMaterial {
    console.log('entity')
    return {
        id: entity.id,
        ref: entity.ref,
        ranges: _.map(entity.ranges, (range: BonusMaterialRange) => createBonusMaterialRangesFromEntity(range)),
    }
}

export function updateBonusMaterialFromMaterial(entity: Material): UpdateBonusMaterial {
    console.log('new');
    return {
        id: uuid4(),
        ref: entity.ref,
        ranges: [defaultCreateBonusMaterialRanges()],
    }
}

export function updateBonusMaterialFromParameters(material: Material, bonusMaterial: BonusMaterial | undefined): UpdateBonusMaterial {
    if (!material)
        return emptyUpdateBonusMaterial();
    if (!bonusMaterial)
        return updateBonusMaterialFromMaterial(material);
    return updateBonusMaterialFromEntity(bonusMaterial);
}

export function createBonusMaterialFromUpdate(updateCommand: UpdateBonusMaterial): CreateBonusMaterial{
    return {
        id: updateCommand.id,
        ref: updateCommand.ref,
    }
}



