
import {computed, defineComponent, ref} from 'vue';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform, onIonViewWillEnter
} from "@ionic/vue";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import {returnDownBackOutline, saveOutline} from "ionicons/icons";
import {modalController} from "@ionic/vue";
import CreateOrUpdateBonusMaterial from "@/app/view/bonus/bonusMaterial/form/CreateOrUpdateBonusMaterialComponent.vue";
import {BonusMaterial} from "@/domain/model/bonusMaterial/bonusMaterial.model";

export default defineComponent({
  name: "BonusMaterialDetailsPage",
  components: {
    CreateOrUpdateBonusMaterial,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonFooter,
    IonIcon,
    IonButton,
  },
  props:{
    materialId: String,
  },
  setup(props: any) {
    const store = useStore();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const submit = ref<boolean>(false);
    const material = computed<Material>(() => store.getters.materialById(props.materialId));
    const bonusMaterial = computed<BonusMaterial>(() => store.getters.bonusMaterialByMaterialRef(material.value.ref));

    const onEndSending = () => submit.value = false;
    const save = () => {
      submit.value = true;
      modalController.dismiss();
    }


    return {
      bonusMaterial,
      submit,
      isTablet,
      material,
      returnDownBackOutline,
      saveOutline,
      modalController,
      save,
      onEndSending,
    }
  },
});
