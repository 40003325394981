
import {computed, defineComponent, ref, watch} from 'vue';
import {IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow} from "@ionic/vue";
import {
  createBonusMaterialFromUpdate,
  CreateBonusMaterialRanges,
  emptyCreateBonusMaterialRanges,
  UpdateBonusMaterial,
  updateBonusMaterialFromParameters
} from "@/domain/command/bonusMaterial/bonusMaterial.command";
import {BonusMaterial} from "@/domain/model/bonusMaterial/bonusMaterial.model";
import {useStore} from "vuex";
import hash from "object-hash";
import {addOutline, closeCircleOutline} from "ionicons/icons";
import _ from "lodash";
import {uuid4} from '@capacitor/core/dist/esm/util';
import {useBonusMaterialApi} from "@/domain/service/api/bonusMaterial.api";
import DinaInput from "@/app/component/input/input.vue";

export default defineComponent({
  name: "CreateOrUpdateBonusMaterial",
  components: {
    DinaInput,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
    // IonButton,
  },
  props: {
    material: Object,
    bonusMaterialId: String,
    submit: Boolean,
  },
  emits: ['on-end-mutations'],
  setup(prop: any, {emit}) {
    const store = useStore();

    const $bonusMaterial = computed<BonusMaterial | undefined>(() => store.getters.bonusMaterialById(prop.bonusMaterialId));
    const command = ref<UpdateBonusMaterial>(updateBonusMaterialFromParameters(prop.material, $bonusMaterial.value));
    const itemToHash = (item: any) => hash(item);

    const onUpdateRangeName = (hashValue: any, newName: string) => {
      command.value.ranges = command.value.ranges.map((range: CreateBonusMaterialRanges) => {
        if (itemToHash(range) === hashValue) {
          range.name = newName;
          return range;
        }
        else return range;
      });
    }
    const addNewRange = () => command.value.ranges.unshift(emptyCreateBonusMaterialRanges());
    const deleteRange = (hashValue: any) => command.value.ranges = _.filter(command.value.ranges, (range: CreateBonusMaterialRanges) => itemToHash(range) !== hashValue);
    const fakeIdOfRange = (item: any) => item.id = uuid4();
    const areValidValues = (commandNode: UpdateBonusMaterial): UpdateBonusMaterial => {
      const validatedCommand: UpdateBonusMaterial = _.cloneDeep(commandNode);
      validatedCommand.ranges = _.filter(commandNode.ranges, (range: CreateBonusMaterialRanges) => !(!range.max || !range.min || !range.money));
      return validatedCommand;
    }
    const onSubmit = async () => {
      if (!$bonusMaterial.value)
        await useBonusMaterialApi().createBonusMaterial(createBonusMaterialFromUpdate(command.value));
      await useBonusMaterialApi().updateBonusMaterial(areValidValues(command.value));
      emit('on-end-mutations');
    };

    watch($bonusMaterial, () => command.value = updateBonusMaterialFromParameters(prop.material, $bonusMaterial.value));
    watch(prop, () => !prop.submit ? '' : onSubmit());

    return {
      command,
      itemToHash,
      addNewRange,
      closeCircleOutline,
      addOutline,
      deleteRange,
      fakeIdOfRange,
      onUpdateRangeName,
    }
  },
});
