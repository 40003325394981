export const DefaultBonusMaterial = 10;

export interface BonusMaterial {
    id: string;
    ref: string;
    ranges: BonusMaterialRange[];
}

export interface BonusMaterialRange{
    min: number;
    max: number;
    money: number;
    name: string;
}

export function emptyBonusMaterialRange(): BonusMaterialRange
{
    return {
        min: 0,
        max: 0,
        money: 0,
        name: '',
    }
}

export function bonusMaterialRangesToString(ranges: BonusMaterialRange[]): string
{
    let rangesString = '';
    ranges.forEach((range: BonusMaterialRange, index) => {
        rangesString = rangesString + range.name + ' = '
            + range.money + ' € '
        if(index !== ranges.length-1)
            rangesString = rangesString + ', ';
    });
    return rangesString;
}