import {http} from "@/domain/service/api/http-api";
import _ from "lodash";
import store from '@/domain/store';
import {CreateBonusMaterial, UpdateBonusMaterial} from "@/domain/command/bonusMaterial/bonusMaterial.command";
import {BonusMaterial} from "@/domain/model/bonusMaterial/bonusMaterial.model";


function sanitizeBonusMaterial(bonusMaterial: any): BonusMaterial{
    const newBonusMaterial = _.cloneDeep(bonusMaterial);
    newBonusMaterial.ranges = bonusMaterial["arrayRanges"];
    return newBonusMaterial;
}

export function useBonusMaterialApi() {
    const fetchAll = async () => {
        const response: any = await http().get('bonusMaterial/');
        response.data.map((bonusMaterial: any) => {
            const sanitizedBonusMaterial = sanitizeBonusMaterial(bonusMaterial);
            store.commit('saveBonusMaterial', sanitizedBonusMaterial);
        })
    };

    const fetchAllMaterialByDate = async (date: number) => {
        const response: any = await http().get('bonus/by-date/'+ date);
        response.data.map((bonusMaterial: any) => {
            const sanitizedBonusMaterial = sanitizeBonusMaterial(bonusMaterial);
            store.commit('saveBonusMaterial', sanitizedBonusMaterial);
        })
    };

    const fetchById = async (id: string) => {
        const response = await http().get('bonusMaterial/' + id);
        const sanitizedBonus = sanitizeBonusMaterial(response.data);
        store.commit('saveBonusMaterial', sanitizedBonus);

    };

    const createBonusMaterial = async (command: CreateBonusMaterial) => {
        const newCommand = _.cloneDeep(command);
        await http().post('bonusMaterial/', newCommand);
        await fetchById(newCommand.id);
    };

    const updateBonusMaterial = async (command: UpdateBonusMaterial) => {
        const newCommand = _.cloneDeep(command);
        await http().post('bonusMaterial/update', newCommand);
        await fetchById(command.id);
    }

    return {fetchAll, fetchById, createBonusMaterial, updateBonusMaterial, fetchAllMaterialByDate};

}