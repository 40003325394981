
import {defineComponent, ref, watch} from 'vue';
import {IonInput} from '@ionic/vue';

export default defineComponent({
  name: 'dina-input',
  components: {
    IonInput,
  },
  props: {
    label: String,
    placeholder: String,
    type: String,
    autocomplete: String,
    autocapitalize: String,
    inputmode: String,
    modelValue: String,
    size: Number,
  },
  setup(prop, {emit}) {
    const value = ref(prop.modelValue);

    watch(value, () => {
      emit('update:modelValue', value.value);
    });

    return {
      value
    };
  },
});
