
import {computed, defineComponent, ref, watch} from 'vue';
import {
  IonCard,
  IonCardContent, IonCol,
  IonContent,
  IonGrid,
  IonHeader, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel,
  IonPage,
  IonRow, IonSearchbar,
  IonTitle,
  IonToolbar, isPlatform, modalController, onIonViewWillEnter
} from "@ionic/vue";
import {Material} from "@/domain/model/material/material.model";
import {useStore} from "vuex";
import {NDataTable, NSpace} from 'naive-ui';
import BonusMaterialDetailsPage from "@/app/view/bonus/bonusMaterial/details/BonusMaterialDetailsPage.vue";
import {BonusMaterial, bonusMaterialRangesToString} from "@/domain/model/bonusMaterial/bonusMaterial.model";
import {useBonusMaterialApi} from "@/domain/service/api/bonusMaterial.api";
import {useMaterialApi} from "@/domain/service/api/material.api";
import _ from "lodash";


export default defineComponent({
  name: "ProductionBonusMaterialPage",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonSearchbar,
    NSpace,
    NDataTable,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonItem,
    IonLabel,
  },
  setup() {
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const store = useStore();
    const {fetchAll} = useBonusMaterialApi();

    const searchWord = ref<string>('');
    const searchMaterial = (material: Material, word: string): boolean => material.ref.toLowerCase().includes(word.toLowerCase()) ||
        material.name.toLowerCase().includes(word.toLowerCase())
        || material.group.toLowerCase().includes(word.toLowerCase());
    const filterMaterial = (materials: Material[], word: string): Material[] => _.filter(materials, (material: Material) => searchMaterial(material, word));
    const allMaterials = computed<Material[]>(() => filterMaterial(store.getters.allProductionMaterials, searchWord.value));

    // const allMaterials = computed<Material[]>(() => store.getters.allMaterial);


    const allBonusMaterials = computed<BonusMaterial[]>(() => store.getters.allBonusMaterial);
    const renderTable = () => allMaterials.value.map((material: Material) => {
      const bonusMaterialByRef: BonusMaterial | undefined = _.find(allBonusMaterials.value, (bonusMaterial: BonusMaterial) => bonusMaterial.ref === material.ref);

      return {
        materialId: material.id,
        ref: material.ref,
        name: material.name,
        default: !bonusMaterialByRef ? '1..999999' : !bonusMaterialByRef.ranges ? '1..999999' : bonusMaterialRangesToString([bonusMaterialByRef.ranges[0]]),
        ranges: !bonusMaterialByRef ? '1..999999' : !bonusMaterialByRef.ranges ? '1..999999' : bonusMaterialRangesToString(bonusMaterialByRef.ranges),
      }
    });
    const data = ref(renderTable());
    const columns = [
      {
        title: 'Referencia',
        key: 'ref',
        sorter: 'default',
        width: 300,
      },
      {
        title: 'Nombre',
        key: 'name',
        sorter: 'default',
        width: 450,
      },
      {
        title: 'Rango por defecto',
        key: 'default',
        sorter: 'default',
        width: 190,
      },
      {
        title: 'Rangos',
        key: 'ranges',
        sorter: 'default',
        width: "50%",
      },
    ];
    const onEditProduct = async (row: any) => {
      const modal = await modalController
          .create({
            component: BonusMaterialDetailsPage,
            componentProps: {
              materialId: row.materialId,
            },
            cssClass: !isTablet.value ? 'modal-half-width' : '',
          });
      return modal.present();
    }

    watch(allBonusMaterials, () => data.value = renderTable(), {immediate: true, deep: true});
    watch(allMaterials, () => data.value = renderTable(), {immediate: true, deep: true});

    onIonViewWillEnter(async () => {
      await useMaterialApi().fetchAll();
      await fetchAll();
    })

    return {
      isTablet,
      onEditProduct,
      searchWord,
      columns,
      pagination: {pageSize: 50},
      data,
      rowProps: (row: any) => {
        return {
          style: 'cursor: pointer;',
          onClick: () => {
            if (row)
              onEditProduct(row);
          },
        };
      },
    }
  },
});
